import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import useDimensions from 'react-use-dimensions';
import { motion } from 'framer-motion'

import { Layout, Slider } from '../components'
import { container, padding, colours, bgImage, wrapper, type, pagePaddingTop, introText, splitSections, hoverState, textHover } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

const ClientServices = (props) => {
	const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const [activeSection, setActiveSection] = useState(false)

	const scrollToSection = (sectionSlug) => {
		const section = document.getElementById(sectionSlug)?.getBoundingClientRect();
		const offset = (section.y - 128) + window.scrollY;

		try {
			window.scroll({
				top: offset,
				left: 0,
				behavior: 'smooth',
			});
		} catch (error) {
			window.scrollTo(offset, 0);
		}
	}

	const renderText = () => {
		if (!data.introduction) return

		return (
			<Intro>
				<Text
					dangerouslySetInnerHTML={{__html: data.introduction}}
				/>
			</Intro>
		)
	}

	const renderNav = () => {
		const items = data.sections.map((item, i) => {
			return (
				<NavItem
					key={i}
					as={'h2'}
					onClick={() => scrollToSection(item.slug)}
					active={activeSection == item.slug}
				>
					{item.title}
				</NavItem>
			)
		})

		return (
			<Sticky>
				<Nav>
					{items}
					{activeSection?.slug}
				</Nav>
			</Sticky>
		)
	}

	const renderTextAccordion = (item) => {
		if (!item.short_description) return
		const [expanded, setExpanded] = useState(false)

		return (
			<TextAccordion>
				<Text
					dangerouslySetInnerHTML={{__html: item.short_description}}
				/>

				{item.use_expanded_description && (
					<>
						<HiddenText
							{...hiddenTextAim}
							animate={expanded ? 'visible' : 'hidden'}
							initial={'hidden'}
							dangerouslySetInnerHTML={{__html: item.expanded_description}}
						/>

						<Subheading
							onClick={() => setExpanded(!expanded)}
						>
							{expanded ? 'Read Less' : 'Read More'}
						</Subheading> 
					</>
				)}
			</TextAccordion>
		)
	}
	
	const renderItems = () => {
		const items = data.sections.map((item, i) => {
			const [ref, { y: scrollY, width }] = useDimensions();

			useEffect(() => {
				if (scrollY > 0 && scrollY < 300) {
					setActiveSection(item.slug)
				}

				if (i == 0 && scrollY > 300) {
					setActiveSection(false)
				}
			}, [scrollY])

			return (
				<Item
					key={i}
					ref={ref}
					id={item.slug}
				>
					<Heading
						className={'mobile-title'}
						dangerouslySetInnerHTML={{__html: item.title}}
					/>

					<Slider
						slides={item.images}
						sliderStyles={sliderStyles}
						hoverCursors={item.images.length > 1}
					/>

					{renderTextAccordion(item)}
				</Item>
			)
		})

		return (
			<Items>
				{items}
			</Items>
		)
	}

	const renderSections = () => {
		if (!data.sections) return

		return (
			<Sections>
				{renderNav()}
				{renderItems()}
			</Sections>
		)
	}

	return (
		<Layout
			meta={data.seo}
			breadcrumb={data.title}
        >
			<Container>
				{renderText()}
				{renderSections()}
			</Container>
		</Layout>
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Item = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
    ${pagePaddingTop}
	flex-direction: column;
    align-items: center;
	height: 100%;
    padding-bottom: 120px;
	
	${media.phone`
		padding-bottom: 32px;
	`}
`

// Intro

const Intro = styled.div`
	${introText}
`

// Sections

const Sections = styled.div`
	${splitSections}
`

// Section Nav

const Sticky = styled.div``

const Nav = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: sticky;
	top: 128px;
`

const NavItem = styled.div`
	${textHover}

	${props => {
		if (props.active) return css`
			  color: ${colours.offWhite};
		`
	}}
`
// Text Accordion (Section Item)

const hiddenTextAim = {
	variants: {
		hidden: {
			height: 0,
			opacity: 0,
			pointerEvents: 'none',
			marginBottom: 0,
			transition: {
				opacity: {
					duration: 0.15
				}
			}
		},
		visible: {
			height: 'auto',
			opacity: 1,
			pointerEvents: 'all',
			marginBottom: 16,
			transition: {
				opacity: {
					delay: 0.25
				}
			}
		},
	},
	transition: {
		duration: 0.35, 
		ease: 'easeInOut',
		times: [0, 0.5, 0.5, 1]
	}
}

const HiddenText = styled(motion.div)``
const TextAccordion = styled.div``

// Section Items

const Items = styled.div`
	${Item} {
		&:not(:last-child) {
			margin-bottom: 55px;

			${media.phone`
				margin-bottom: 42px;
			`}
		}

		${Heading} {
			margin-bottom: 16px;
		}

		${Text},
		${Subheading},
		${HiddenText} {
			margin-top: 16px;

			&, p {
				${type.bodySmall};
			}
		}

		${Subheading} {
			margin-top: 0;
			cursor: pointer;
			${textHover}
		}
	}
`

// Slider (Section Item)

const slideHeight = '32.6vw';

const sliderStyles = css`
	overflow: visible;
	height: ${slideHeight};
	width: 100%;

	${media.phone`
		height: 256px;
	`}

	.slide {
		background: #F9F8F5;
	}

	.image-wrapper {
		background: #F9F8F5;

		img {
			width: 100%;
			height: auto;
			object-fit: contain;

			${media.phone`
				height: 256px;
			`}
		}
	}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "client-services" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`


export default ClientServices